<template>
    <v-card class="mx-auto"
            max-width="500"
            outlined>
        <v-chip class="ma-2"
                style="margin-top: -15px !important;">
            Locations
        </v-chip>
        <v-btn color="green"
               dark
               small
               absolute
               top
               right
               fab
               @click="createLocationListRow()">
            <v-icon size="medium">fas fa-plus</v-icon>
        </v-btn>

        <v-container fluid
                     grid-list-lg>

            <LocationListRow v-for="row in locationList" :key="row.id"
                             v-bind:id="row.id" v-on:remove="removeRow($event)"
                             v-on:updateItem="updateRow($event)">
            </LocationListRow>

        </v-container>
    </v-card>
</template>

<script>
    import { globalFunctions } from '../../javascript/globalFunctions'

    export default {
        components: {
            LocationListRow: () => import('./locationListRow.vue')
        },
        data() {
            return {
                locationList: [],
                locationListRow: {
                    id: 0,
                    locationName: '',
                    locationAddress: ''
                }
            }
        },
        methods: {
            createLocationListRow: function () {
                this.locationListRow.id = globalFunctions.getNextIdForObject(this.locationList)

                this.locationList.push(globalFunctions.stringify(this.locationListRow))
            },
            removeRow: function (id) {
                for (let i = 0; i < this.locationList.length; i++) {
                    if (this.locationList[i].id === id) {
                        this.locationList.splice(i, 1)
                        break;
                    }
                }
                this.returnLocationList()
            },
            updateRow: function (row) {
                for (let i = 0; i < this.locationList.length; i++) {
                    if (this.locationList[i].id === row.id) {
                        this.locationList[i].locationName = row.locationName
                        this.locationList[i].locationAddress = row.locationAddress

                        break;
                    }
                }
                this.returnLocationList()
            },
            returnLocationList: function () {
                this.$emit('locationList', this.locationList)
            }
        },
        beforeMount() {
            this.createLocationListRow()
        }
    }
</script>